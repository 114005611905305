// ConsentForm.js
import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Container,
  Box,
  Paper,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import StaticLayout from './StaticLayout';

const ConsentFormWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const RequiredAsterisk = styled('span')(({ theme }) => ({
  color: 'black',
}));

const ConsentForm = () => {
  const [newsletter, setNewsletter] = useState(false);
  const [terms, setTerms] = useState(false);
  const queryParams = new URLSearchParams(window.location.search)
  const state = queryParams.get("state")

  const handleSubmit = (event) => {
    event.preventDefault();
    window.location.href = "https://" + process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN + "/continue?state=" + state
  };


  return (
    <StaticLayout>
    <ConsentFormWrapper style={{ minHeight: '80vh'}}>
        <StyledPaper elevation={4}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            Consent Form
          </Typography>
          <StyledForm onSubmit={handleSubmit}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Checkbox
                  checked={terms}
                  onChange={(e) => setTerms(e.target.checked)}
                  required
                />
              </Grid>
              <Grid item>
                <Typography>
                  I accept the <a href="https://raiyo.co/terms-of-service">Terms & Conditions</a> and{' '}
                  <a href="https://raiyo.co/privacy">Privacy Policy</a>
                  <RequiredAsterisk> *</RequiredAsterisk>
                </Typography>
              </Grid>
            </Grid>
            <StyledButton variant="contained" color="primary" type="submit">
              Submit
            </StyledButton>
          </StyledForm>
        </StyledPaper>
    </ConsentFormWrapper>
    </StaticLayout>
  );
};

export default ConsentForm;