import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#0F09A1',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  title: {
    textDecoration: 'none',
    color: 'inherit',
  },
  linkButton: {
    color: 'white',
    textDecoration: 'none',
  },
}));

const Header = () => {
  const classes = useStyles();
  const { logout } = useAuth0();
  
  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div>
        <Button component={RouterLink} to="/" className={classes.linkButton}>
            Home
          </Button>
          <Button component={RouterLink} to="/updatephone" className={classes.linkButton}>
            Change Number
          </Button>
          <Button component={RouterLink} to="/plankton" className={classes.linkButton}>
            Manage Plankton
          </Button>
        </div>
        <Button 
          onClick={() => logout({ logoutParams: {returnTo: "https://raiyo.co" }})}  // Call logout on click
          className={classes.linkButton}
        >
          LogOut
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;