import React from 'react';
import Box from '@mui/material/Box';
import { useAuth0 } from '@auth0/auth0-react';
import Footer from './Footer';

const StaticLayout = ({ children }) => {
  const { logout } = useAuth0(); 
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      style={{ backgroundColor: '#EAF2FF' }}
    >
      <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="center">
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default StaticLayout;