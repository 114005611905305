import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
});

export const postRegistration = async (data, token) => {
  const payload = {
    "FirstName": data.firstname,
    "LastName": data.lastname,
    "BirthDate": data.birthdate,
    "NewsLetterConsent": data.newsletterconsent
  }

  try {
    const response = await apiService.post('/v1/user/registration', payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    // Return an object containing the response status and any other required data
    return { status: response.status, data: response.data };
  } catch (error) {
    // Return an object with an error status and error message
    return { status: error.response.status, data: error.response.data };
  }
};

export const verifynumber = async (data, token) => {
  const payload = {
    "phoneNumber": data.phoneNumber,
  }

  try {
    const response = await apiService.post('/v1/verifynumber', payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    // Return an object containing the response status and any other required data
    return { status: response.status, data: response.data };
  } catch (error) {
    return { status: error.response.status, data: error.response.data };
  }
};

export const verifynumbercode = async (data, token) => {
  const payload = {
    "phoneNumber": data.phoneNumber,
    "verificationCode": data.verificationCode,
  }

  try {
    const response = await apiService.post('/v1/verifynumber/code', payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    return { status: error.response.status, data: error.response.data };
  }
};

export const getPhoneNumber = async (token) => {
  try {
    const response = await apiService.get('/v1/whatsappnumber', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { status: response.status, phoneNumber: response.data.phoneNumber , whatsAppNumber: response.data.whatsAppNumber, whatsAppLink: response.data.whatsAppLink };
  } catch (error) {
    return { status: 'error', message: error.message };
  }
};

export const getWhatsAppQrCode = async (token) => {
  try {
    const response = await apiService.get('/v1/whatsappqrcode', {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { status: response.status, data: response.data} ;
  } catch (error) {
    return { status: 'error', message: error.message };
  }
};

export const updatestatus = async (token) => {
  try {
    const response = await apiService.get('/v1/updatestatus', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { status: response.status };
  } catch (error) {
    return { status: error.response.status, data: error.response.data };
  }
};

export const getPlankton = async (token) => {
  try {
    const response = await apiService.get('/v1/credits', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    return { status: 'error', message: error.message };
  }
};

export const buyPlankton = async (token, tier) => {
  try {
    const response = await apiService.post(`/v1/credits/${tier}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    return { status: 'error', message: error.message };
  }
};

export const manageRedirect = async (token) => {
  try {
    const response = await apiService.post('/v1/managepayment', {}, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    return { status: 'error', message: error.message };
  }
};