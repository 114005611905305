import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './App.css';
import Registration from './components/Registration';
import UpdatePhoneInit from './components/UpdatePhoneInit';
import EmailVerification from './components/EmailVerification';
import { AuthenticationGuard } from './components/AuthenticationGuard';
import ConsentForm from './components/ConsentForm';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import useCheckCustomClaim from './hooks/useCheckRegistration';
import CreditSuccess from './components/creditsuccess';
import { getPhoneNumber } from './services/apiService';
import { useNavigate } from "react-router-dom";
import Plankton from './components/Plankton';
import UpdatePhone from './components/UpdatePhone';
import FooterLayout from './components/FooterLayout';
import StaticLayout from './components/StaticLayout';

import { makeStyles } from '@mui/styles';
import ManagePlankton from './components/ManagePlankton';
import Layout from './components/Layout';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() =>
        loginWithRedirect({
          screen_hint: 'signup',
        })
      }
    >
      Sign Up
    </button>
  );
};

const SignInButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() =>
        loginWithRedirect({
          screen_hint: 'signin',
        })
      }
    >
      Sign In
    </button>
  );
};

const LogOutButton = () => {
  const { logout } = useAuth0();

  return (
    <button
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      Log Out
    </button>
  );
};


const Home = () => {
  const { isRegistered, updateRegistrationStatus } = useCheckCustomClaim();
  const [render, setRender] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [whatsAppNumber, setWhatsAppNumber] = useState(null);
  const [whatsAppLink, setWhatsAppLink] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    updateRegistrationStatus();
  }, [updateRegistrationStatus])

  useEffect(() => {
    if (isRegistered) {
      const fetchWhatsAppNumber = async () => {
        const token = await getAccessTokenSilently();
        const response = await getPhoneNumber(token)
        const phoneNumber = response.phoneNumber;
        const whatsAppNumber = response.whatsAppNumber.replace('+', '');
        const whatsAppLink = response.whatsAppLink
        if (phoneNumber === null) {
          navigate('/phoneinit')
        }
        setRender(true);
        setPhoneNumber(phoneNumber);
        setWhatsAppNumber(whatsAppNumber);
        setWhatsAppLink(whatsAppLink);
      }
      fetchWhatsAppNumber();
    }
  }, [isRegistered, getAccessTokenSilently, navigate]
  );
  if (isRegistered === null) {
    return  (<Layout></Layout>)
  } else if (isRegistered === false) {
    return (
      <div>
        <FooterLayout>
        <Registration />
        </FooterLayout>
      </div>
    )
  } else if (!render) {
    return (<Layout></Layout>)
  }
  return (
    <div>
      <Layout>
        <Plankton whatsAppNumber={whatsAppNumber} whatsAppLink={whatsAppLink}/>
      </Layout>
    </div>
  )

};

const Phoneinit = () => {
  return (
    <div>
      <FooterLayout>
      <UpdatePhoneInit />
      </FooterLayout>
    </div>
  )
}

const Phoneupdate = () => {
  return (
    <div>
      <Layout>
      <UpdatePhone />
      </Layout>
    </div>
  )
}

const EmailVerificationView = () => {
  return (
      <StaticLayout>
      <EmailVerification />
      </StaticLayout>
  )
}

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <CssBaseline />
        <Routes>
          <Route exact path="/" element={<AuthenticationGuard component={Home} />} />
          <Route exact path="/credits/success" element={<AuthenticationGuard component={CreditSuccess} />} />
          <Route exact path="/phoneinit" element={<AuthenticationGuard component={Phoneinit} />} />
          <Route exact path="/updatephone" element={<AuthenticationGuard component={Phoneupdate} />} />
          <Route exact path="/plankton" element={<AuthenticationGuard component={ManagePlankton} />} />
          <Route path="/email-verification" element={<EmailVerificationView />} />
          <Route path="/consentform" element={<ConsentForm />} />
        </Routes>
    </LocalizationProvider>
  );
}

export default App;