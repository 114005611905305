import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useCheckRegistration = () => {
  const [isRegistered, setIsRegistered] = useState(null);
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

  const updateRegistrationStatus = async () => {
    try {
        const accessToken = await getAccessTokenSilently();
        const tokenClaims = await getIdTokenClaims();
        const registrationClaim = 'registration';
        if (tokenClaims[registrationClaim] === 1) {
            setIsRegistered(true);
        }
        else {
            setIsRegistered(false);
        }
    } catch (error) {
        console.error('Error checking registration claim:', error);
    }
  }
  
  return {isRegistered, updateRegistrationStatus};
};

export default useCheckRegistration;