import React, { useState, useEffect } from 'react';
import * as dayjs from 'dayjs'
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { postRegistration } from '../services/apiService';
import { DatePicker } from '@mui/x-date-pickers';
import useCheckRegistration from '../hooks/useCheckRegistration';

const getMaxDateForOver18 = () => {
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  return maxDate;
};

const getMinDate = () => {
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 130,
    today.getMonth(),
    today.getDate()
  );
  return getMinDate;
};

const Registration = () => {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthdate, setBirthdate] = useState(null);
  const [newsletter, setNewsletter] = useState(false);
  const [error, setError] = useState('');
  const { updateRegistrationStatus } = useCheckRegistration();

  const maxDate = getMaxDateForOver18()
  const minDate = getMinDate()

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isAboveEighteen(birthdate)) {
      setError('You must be at least 18 years old.');
      return;
    }
    setError('');
    const data = { 
      "firstname": firstName, 
      "lastname": lastName,
      "newsletterconsent": newsletter,
      "birthdate": birthdate.toISOString()
     };
    try {
      const token = await getAccessTokenSilently();
      const response = await postRegistration(data, token);
      // We need to update the tokens to the new claims
      if (response.status === 200) {
        await getAccessTokenSilently({cacheMode: 'off'})
        await getIdTokenClaims({cacheMode: 'off'})
        updateRegistrationStatus();
      }
      else {
        setError("An error occurred. Please try again.")
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const isAboveEighteen = (birthdate) => {
    const birth = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age >= 18;
  };

  return (
    <Grid container maxWidth="xs" justifyContent="center" alignItems="center" style={{ minHeight: '80vh'}}>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Paper component={Box} p={3} elevation={3}>
          <Typography variant="h5" align="center">
            Finish Your Account
          </Typography>
          <Box component="form" onSubmit={handleSubmit} mt={2}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              margin="normal"
              required
            />
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              margin="normal"
              required
            />
            <DatePicker
              label="Date of Birth"
              value={birthdate}
              format="DD/MM/YYYY"
              maxDate={dayjs(maxDate)}
              minDate={dayjs(minDate)}
              onChange={(newValue) => {
                setBirthdate(newValue)}
              }
              slotProps={{ textField: { fullWidth: true } }}
            />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newsletter}
                    onChange={(e) => setNewsletter(e.target.checked)}
                    color="primary"
                  />
                }
                label="Subscribe to newsletter"
                sx={{ ml: -0.5 }}
              />
            </Grid>
            {error && (
              <Typography variant="body1" color="error">
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Registration;