import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import {
    Box,
    Container,
    Button,
    Snackbar,
    Paper,
    Typography
  } from "@mui/material";
import { getPlankton, buyPlankton, manageRedirect, getWhatsAppQrCode } from '../services/apiService';
import MuiAlert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import WhatsAppButton from "../assets/WhatsAppButtonWhiteMedium.svg";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
    paper: {
      padding: '32px', 
      borderRadius: "16px",
      textAlign: "center",
      display: 'flex',
      flexDirection: 'column',
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      maxWidth: "400px",
      marginBottom: '16px',
    },
    qrImage: {
      width: "200px",
      height: "200px",
    },
    backButton: {
      alignSelf: "flex-start",
      marginBottom: '16px',
    },
  }));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PlanktonCredits = ({ whatsAppNumber, whatsAppLink }) => {
    const classes = useStyles();
    const [loadingPlanktonStatus, setLoadingPlanktonStatus] = useState(true);
    const [qrCode, setQrCode] = useState(null);
    const { getAccessTokenSilently } = useAuth0();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const navigate = useNavigate();
    const handleClose = () => {
        setSnackbarOpen(false);
      };
    
    const handleBuyPlankton = async (event) => {
        event.preventDefault();
        navigate("/plankton")
    };

    const handleManagePlankton = async (event) => {
        event.preventDefault();
        try {
          const token = await getAccessTokenSilently();    
          const response = await manageRedirect(token)
          if (response.status === 200) {
            window.location.href = response.data.redirectUrl;
          }
        } catch (error) {
          console.error("Error:", error);
        }
    };

    
    useEffect(() => {
        const fetchPlanktonStatus = async () => {
          setLoadingPlanktonStatus(true);
            try {
              const token = await getAccessTokenSilently();
              const qr_response = await getWhatsAppQrCode(token)
              if (qr_response.status === 200) {
                setQrCode(URL.createObjectURL(qr_response.data));
              }
            } catch (error) {
              console.error("Error fetching Plankton status:", error);
            } finally {
              setLoadingPlanktonStatus(false);
            }
        };
        fetchPlanktonStatus();
    }, []);

    useEffect(() => {
      return () => {
        URL.revokeObjectURL(qrCode);
      };
    }, []);
  
    return (
        <Container className={classes.container}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
              {!loadingPlanktonStatus
              && (
                <Box mt={4}>
                  <Paper className={classes.paper}>
                  <Typography>Reach Raiyo under +{whatsAppNumber}</Typography>
                  <Link to={whatsAppLink} target="_blank" rel="noreferrer"> <img alt="Chat on WhatsApp" src={WhatsAppButton}/></Link>
                  <Link to={whatsAppLink} target="_blank" rel="noreferrer"> <img className={classes.qrImage} alt="Chat on WhatsApp" src={qrCode}/></Link>
                    <Button variant="contained" color="secondary" onClick={handleBuyPlankton}>
                      Manage Plankton
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleManagePlankton}>
                      Manage Invoices
                    </Button>
                  </Paper>
                </Box>
              )}
          </Box>
            <Box>
              {loadingPlanktonStatus && <CircularProgress/> }
            </Box>
        </Container>
      );      
}

export default PlanktonCredits;