import React from 'react';
import Box from '@mui/material/Box';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      style={{ backgroundColor: '#EAF2FF' }}
    >
      <Header />
      <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="center">
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;