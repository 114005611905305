import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import styled  from '@emotion/styled';

const StyledFooter = styled(Box)`
  background-color: #0F09A1;
  color: white;
  padding: 20px 0;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <Container maxWidth="md">
        <Typography variant="body2" align="center">
          © {currentYear} Cloudtone GmbH. All rights reserved.
        </Typography>
        <Typography variant="body2" align="center">
          <Link href="https://raiyo.co/privacy" color="inherit" underline="hover">
            Privacy Policy
          </Link>{' '}
          |{' '}
          <Link href="https://raiyo.co/terms-of-service" color="inherit" underline="hover">
            Terms of Service
          </Link>{' '}
          |{' '}
          <Link href="https://raiyo.co/legal-notice" color="inherit" underline="hover">
            Legal Notice
          </Link>
        </Typography>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
