import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const EmailVerification = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" minHeight="80vh">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Typography variant="h3" gutterBottom>
          Email Verification
        </Typography>
        <Typography variant="h6">
          Please check your email for a verification link.
        </Typography>
        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // Redirect to the main page or any desired page
              navigate("/")
            }}
          >
            I have verified my email
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default EmailVerification;
