import React, { useState, useEffect } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
  Slide,
  IconButton,
  Paper
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MuiAlert from "@mui/material/Alert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { verifynumber, verifynumbercode, updatestatus } from '../services/apiService';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: '32px', // Add padding to the Paper component
    borderRadius: "16px",
    maxWidth: "600px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
    marginBottom: '1px', // Add margin below the form
  },
  backButton: {
    alignSelf: "flex-start",
    marginBottom: '16px', // Add margin below the back button
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UpdatePhone = () => {
  const classes = useStyles();
  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(true);
  const [phoneUpdateNotAllowed, setPhoneUpdateNotAllowed] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [showVerificationBox, setShowVerificationBox] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const handleNumberChange = async (event) => {
    event.preventDefault();
    // Call the API to send the verification code to the new WhatsApp number
    // Replace this URL with your actual API endpoint
    const token = await getAccessTokenSilently();
    const response = await verifynumber({ phoneNumber: '+' + whatsAppNumber }, token)
    if (response.status === 200) {
      setAlertSeverity("success");
      setAlertMessage("Verification code sent!");
      setShowVerificationBox(true);
    } else if (response.status === 429) {
      setAlertSeverity("error");
      setAlertMessage("Too many attempts. Please try again later.");
    } else if (response.status === 409) {
      setAlertSeverity("error");
      setAlertMessage("Your account is already linked to this number");
    } else {
      setAlertSeverity("error");
      setAlertMessage("Error sending verification code. Please try again later.");
    }
    setSnackbarOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = await getAccessTokenSilently();
    const response = await verifynumbercode({ phoneNumber: '+' + whatsAppNumber, verificationCode: verificationCode }, token)
    if (response.status === 200) {
      setAlertSeverity("success");
      setAlertMessage("Raiyo is ready to talk to you!");
      navigate("/");
    } else if (response.status === 429) {
      setAlertSeverity("error");
      setAlertMessage("Too many attempts. Please try again later.");
    } else if (response.status === 409) {
      setAlertSeverity("error");
      setAlertMessage("The number you verified is already linked to another account. Please log in with that account or contact the support.");
    } else {
      setAlertSeverity("error");
      setAlertMessage("Wrong verification code");
    }
    setSnackbarOpen(true);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const checkUpdatePermission = async () => {
      const token = await getAccessTokenSilently();
      const response = await updatestatus(token);
      if (response.status === 210) {
        setPhoneUpdateNotAllowed(true);
      } else if (response.status === 200) {
        setIsLoading(false);
      } else {
        setAlertSeverity("error");
        setAlertMessage("An error occured. Please try again later.");
      };
    }
    checkUpdatePermission();
  }, []);

  return (
    isLoading ? (
      <Container className={classes.container}>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Box>
            <Alert onClose={handleClose} severity={alertSeverity}>
              {alertMessage}
            </Alert>
          </Box>
        </Snackbar>
        {phoneUpdateNotAllowed && (
          <Paper className={classes.paper} elevation={3} >
          <Typography variant="h6" align="center">
            Phone number updates are only allowed once a week.
            Please try again later.
          </Typography>
          </Paper>
        )}
      </Container>
    )  :  (
    <Container className={classes.container}>
      <Paper className={classes.paper} elevation={3} >
      
      <Box marginBottom="30px">
        <Typography variant="h6">Update your WhatsApp number</Typography>
      </Box>
        {showVerificationBox && (
          <>
          <Typography variant="h4" style={{ marginBottom: 8 }}>
            Verification code sent
          </Typography>
          <Typography variant="h6" style={{ marginBottom: 8 }}>
          Please enter the code below to complete the registration.
        </Typography>
        </>
        )}
        {!showVerificationBox && (
          <Typography variant="h4" gutterBottom>
          </Typography>
        )}
        <form className={classes.form} onSubmit={handleSubmit}>
          <Slide in={!showVerificationBox} direction="right">
            <Box>
              <PhoneInput
                country={'de'}
                value={whatsAppNumber}
                onChange={phone => setWhatsAppNumber(phone)}
                marginTop="16px"
              />
              <Box justifyContent="center" marginTop="20px">
              <Button
                variant="contained"
                color="primary"
                onClick={handleNumberChange}
              >
                Submit
              </Button>
              </Box>
            </Box>
          </Slide>
          {showVerificationBox && (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <IconButton
                className={classes.backButton}
                onClick={() => {
                  setShowVerificationBox(false)
                  setVerificationCode("")
                }
                }
              >
                <ArrowBackIcon />
              </IconButton>
                <Box>
                  <TextField
                    label="Verification Code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!verificationCode}
                  >
                    Verify Number
                  </Button>
                </Box>
              </Box>
          )}
        </form>
      </Paper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Box>
            <Alert onClose={handleClose} severity={alertSeverity}>
              {alertMessage}
            </Alert>
        </Box>
      </Snackbar>
    </Container>
    )
  );
};

export default UpdatePhone;