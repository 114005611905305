import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const PageLoader = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      minWidth="100vw"
      bgcolor="rgba(255, 255, 255, 0.8)"
      position="fixed"
      top={0}
      left={0}
      zIndex={9999}
    >
      <CircularProgress />
    </Box>
  );
};

export default PageLoader;