import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Card, CardContent, Typography, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getPlankton, buyPlankton } from '../services/apiService';
import Layout from './Layout';
import img_7 from '../assets/7.jpg';
import img_30 from '../assets/30.jpg';
import img_90 from '../assets/90.jpg';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    minWidth: 300,
    textAlign: 'center',
    marginBottom: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  productCard: {
    maxWidth: 345,
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 350,
    padding: '1rem',
  },
  media: {
    height: 140,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    maxWidth: 300,
  },
  tableContainer: {
    maxWidth: 300,
    marginTop: '1rem',
    marginBottom: '1rem',
  }
});

const ManagePlankton = () => {
  const classes = useStyles();
  const [planktonData, setPlanktonData] = useState({"active":[], "inactive": []});
  const [showInactive, setShowInactive] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const toggleInactive = () => {
    setShowInactive(!showInactive);
  };

  const handleBuyPlankton = async (event, tier) => {
    event.preventDefault();
    try {
      const token = await getAccessTokenSilently();    
      const response = await buyPlankton(token, tier)
      if (response.status === 200) {
        window.location.href = response.data.redirectUrl;
      }
    } catch (error) {
      console.error("Error:", error);
    }
};

  const products = [
    { title: "Tentacle Treats", description: "Expires after 7 days", image: img_7, tier: 1, n: "50,000", price: "3.99€"},
    { title: "Ocean's Finest", description: "Expires after 30 days", image: img_30, tier: 2, n: "200,000", price: "10.99€"},
    { title: "Kraken's Choice", description: "Expires after 90 days", image: img_90, tier: 3, n: "600,000", price: "27.99€"},
  ];

  useEffect(() => {
    const fetchPlanktonStatus = async () => {
        try {
          const token = await getAccessTokenSilently();
          const response = await getPlankton(token)
          console.log(response.data);
          if (response.status === 200) {
            setPlanktonData({
                "active":response.data.active, "inactive": response.data.inactive});
          }
        } catch (error) {
          console.error("Error fetching Plankton status:", error);
        }
    };
    fetchPlanktonStatus();
}, []);

  return (
    <Layout>
      <Box className={classes.root}>
        <Box className={classes.box}>
        <h2>Buy Plankton</h2>
        <Grid 
            container
            spacing={3}
        >
          {products.map((product, index) => (
            <Grid align="center" justify="center" item xs key={index}>
              <Card className={classes.productCard}>
                <CardContent className={classes.card}>
                  <img src={product.image} alt={product.title} className={classes.media} />
                  <Typography gutterBottom variant="h5" component="div">
                    {product.title}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {product.n} Plankton
                  </Typography>
                  <Typography variant="body2">
                    {product.description}
                  </Typography>
                </CardContent>
                <Box marginBottom="20px">
                <Typography variant="h6">
                    {product.price}
                  </Typography>
                  <Box marginTop={1}>
                <Button onClick={(e) => {
                    handleBuyPlankton(e, product.tier);
                }} variant="contained" color="primary">
                  Buy
                </Button>
                </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
        <h2>My Fridge</h2>
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table  textAlign="center"  className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><Typography sx={{ fontWeight: 'bold' }}>Remaining Plankton</Typography></TableCell>
                        <TableCell align="right"><Typography sx={{ fontWeight: 'bold' }}>Expiration</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {planktonData.active.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {row.credits}
                            </TableCell>
                            <TableCell align="right">{new Date(row.expiration).toLocaleDateString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        <h2>Trash Can</h2>
        <Button variant="contained" color="primary" onClick={toggleInactive}>
          {showInactive ? 'Hide Trash Can' : 'Show Trash Can'}
        </Button>
        {showInactive && (
          <TableContainer component={Paper} className={classes.tableContainer}>
              <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                      <TableRow>
                      <TableCell><Typography sx={{ fontWeight: 'bold' }}>Expired Plankton</Typography></TableCell>
                        <TableCell align="right"><Typography sx={{ fontWeight: 'bold' }}>Expiration</Typography></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {planktonData.inactive.map((row,index) => (
                          <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                  {row.credits}
                              </TableCell>
                              <TableCell align="right">{new Date(row.expiration).toLocaleDateString()}</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
        )}
      </Box>
      </Box>
    </Layout>
  );
};

export default ManagePlankton;

