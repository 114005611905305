import React from 'react';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import Layout from './Layout';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    minWidth: 275,
    textAlign: 'center',
  },
  backButton: {
    marginTop: '1rem',
  },
});

const CreditSuccess = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Layout>
    <Box className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Success!
          </Typography>
          <Typography variant="body2" component="p">
            You have successfully bought credits! The Plankton will be added to your Fridge after the transaction is confirmed.
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Button
        className={classes.backButton}
        startIcon={<ArrowBackIcon />}
        variant="contained"
        color="primary"
        onClick={() => {
          navigate('/plankton')
        }}
      >
        Go Back
      </Button>
    </Box>
    </Layout>
  );
};

export default CreditSuccess;